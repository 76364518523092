<template>
<form @submit.prevent="">
    <div class="form-row" v-if="errors.length">
        <div class="col-12">
            <div class="alert alert-danger">
                <strong>خطا های زیر را رفع کنید : </strong>
                <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                </ul>
            </div>
        </div>
    </div>
    <slot v-if="widgetLoad">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>

        <div v-if="!isMobile" class="tab-pane fade active show" id="ons-buy" role="tabpanel" aria-labelledby="ons-buy-tab">
            <div class="trading-head">
                <div class="form-row">
                    <div class="col-12 col-md-7">
                        <ul class="buy-ul-list">
                            <li>
                                قیمت فعلی هر واحد : <strong>{{ formatPrice(price) }}</strong>
                            </li>
                            <li>
                                واحد ارزش : <strong>{{ type }}</strong>
                            </li>
                            <li>
                                جمع ارزش فروش : <strong>{{ formatPrice(count * price) }}</strong>
                            </li>
                            <li>
                                تعداد دارایی : <strong>{{ select_inventory.amount  }}</strong>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-md-5">
                        <label for="inventory_list">دارایی‌ها</label>
                        <div class="widget-select w-100 calendar-tags widget-select-lg">
                            <v-select2 v-model="select_inventory" :clearable="false" :options="inventory_list">
                            </v-select2>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12">
                        <label for="title">تعداد</label>
                        <input type="number" class="widget-input input-no-bg input-lg input-set-border w-100" id="title" v-model="count">
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isMobile" class="tab-pane fade active show" id="ons-buy" role="tabpanel" aria-labelledby="ons-buy-tab">
            <div class="trading-head">
                <div class="form-row">
                    <div class="col-12 col-md-12">
                        <ul class="buy-ul-list" style="margin-bottom: 0;">
                            <li>
                                قیمت فعلی هر واحد : <strong>{{ formatPrice(price) }}</strong>
                            </li>
                            <li>
                                واحد ارزش : <strong>{{ type }}</strong>
                            </li>
                            <li>
                                جمع ارزش فروش : <strong>{{ formatPrice(count * price) }}</strong>
                            </li>
                            <li>
                                تعداد دارایی : <strong>{{ select_inventory.amount  }}</strong>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="form-row form-row-mobile-flex-modal">
                    <div class="col-12 col-md-4">
                        <v-select v-model="select_inventory.amount" style="margin-left: 10px !important;margin-top: 4px !important;" class="profile-mobile-view-select mobile-view-select-modal fit-h" :items="inventory_list" item-text="label"  item-value="id" label="دارایی‌ها" outlined></v-select>
                        <v-text-field type="number" class="mobile-app-input mobile-app-input-modal" label="تعداد" id="title" v-model="count"></v-text-field>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row text-center mt-4">
            <div class="col-12">
                <a href="" @click.prevent="sell" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-danger show-widgets-modal"><i class="uil uil-shopping-cart-alt "></i> ثبت سفارش فروش</a>
            </div>
        </div>
    </slot>
</form>
</template>

<style lang="scss">
    #main-header {
        z-index: 100000000;
    }

    #bug-report-modal,
    .modal[role="dialog"] {
        z-index: 999999999;
    }

    #indicator-modal {
        z-index: 2147483647;
    }
</style>

<script>
// این کامپوننت مودال فروش سرمایه گذاری میباشد
export default {
    name: 'InvestmentSellModal',
    props: ['data'],
    components: {},
    data: function () {
        return {
            inventory_list: [],
            select_inventory: [{
                label: 'انتخاب کنید',
                amount: 0,
            }],
            errors: [],
            price: 0,
            count: 0,
            type: '-',
            widgetLoad: true,
            isMobile:false,            
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.loadData();
            this.getMyInventory();
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // تزریق و مقدار دهی اولیه به کامپوننت
        loadData() {
            this.select_inventory = [];
            this.select_inventory = {
                label: '-',
                amount: 0,
            };
            this.errors = [];
            this.type = '-';
            this.price = 0;
            this.count = 0;
        },
        // این متد وظیفه هندل کردن درخواست فروش را دارد
        sell() {
            this.errors = [];

            if (parseFloat(this.count) <= 0) {
                this.errors.push('تعداد را وارد کنید');
            } else {
                if (parseFloat(this.count) > parseFloat(this.select_inventory.amount)) {
                    this.errors.push('تعداد انتخاب شده از حد دارایی شما بیشتر است');
                }
            }

            if (this.errors.length) {
                return false;
            }

            let api_data = {
                asset: this.select_inventory.asset,
                count: this.count,
            };

            this.widgetLoad = true;
            this.$helpers.makeRequest('POST', '/investment/sell', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.data.response.message == 'amount-out-of-range') {
                    this.errors.push('تعداد انتخاب شده از حد دارایی شما بیشتر است');
                } else {
                    let event = 'success';
                    this.$parent.emitData(event, {}, true);
                }
                this.widgetLoad = false;
            });
        },
        getMyInventory() {
            //ارسال درخواست ای پی آی جهت دریافت دارایی‌ها
            this.$helpers.makeRequest('GET', '/investment/my-inventory/').then(api_response => {
                this.$helpers.api_alert(api_response);
                this.inventory_list = api_response.data.response.inventory;
                this.widgetLoad = false;
            });
        },
        getPrice(symbol) {
            //ارسال درخواست ای پی آی جهت دریافت قیمت شاخص مورد نظر
            this.widgetLoad = true;
            this.$helpers.makeRequest('GET', '/investment/market-price/' + symbol).then(api_response => {
                this.$helpers.api_alert(api_response);
                this.price = api_response.data.response.price.p;
                this.type = api_response.data.response.type;
                this.widgetLoad = false;
            });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
    },
    watch: {
        price: function (val) {
            this.price = val;
        },
        inventory_list: function (val) {
            this.select_inventory = val[0];
        },
        select_inventory: function (val) {
            if (val.amount > 0) {
                this.count = val.amount;
                this.getPrice(val.asset);
            }
        },
    },
}
</script>
